"use client";

import { RegularLinkButton } from "@/components/Button/RegularLinkButton/RegularLinkButton.component";
import type { CommonButtonProps } from "@/components/Button/regular-button.types";
import { useUser } from "@/contexts/user/hooks/use-user.hook";
import type { ReactNode } from "react";
type LoginButtonProps = Pick<CommonButtonProps, "position" | "variant"> & {
  readonly className?: string;
  readonly children: ReactNode;
  readonly redirectTo?: string;
};
export function LoginButton({
  children,
  className,
  position,
  redirectTo,
  variant = "primary"
}: LoginButtonProps) {
  const {
    urls: {
      createLoginUrl,
      loginAndReturnUrl
    }
  } = useUser();
  return <RegularLinkButton className={className} href={redirectTo !== undefined ? createLoginUrl(redirectTo) : loginAndReturnUrl} openInNewTab={false} position={position} variant={variant} data-sentry-element="RegularLinkButton" data-sentry-component="LoginButton" data-sentry-source-file="LoginButton.component.tsx">
      {children}
    </RegularLinkButton>;
}